<template>
  <div class="container-fluid">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h3>Cadastro de usuário</h3>
					</div>
				</div>
			</div>
			<form @submit.prevent="salvar">
				<div class="card-body">
					<div class="row">
						<div class="col-md-3 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.usuario.nome.$error }">
								<label for="nome" class="required">Nome</label>
								<input v-model="usuario.nome" type="text" class="form-control" name="nome" id="nome">
								<div class="error-form text-danger" v-if="!$v.usuario.nome.required">Campo obrigatório</div>
							</div>
						</div>
            <div class="col-md-3 col-sm-12">
							<div class="form-group">
								<label for="email" >Email</label>
								<input v-model="usuario.email" type="email" class="form-control" name="email" id="email">
							</div>
						</div>
            <div class="col-md-6 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.usuario.acesso_perfil_id.$error }">
								<label for="acesso_perfil_id" class="required">Perfil de acesso</label>
								<select class="form-control" name="acesso_perfil_id" id="acesso_perfil_id" v-model="usuario.acesso_perfil_id">
                  <option v-for="(item, key) in perfis" :key="key" :value="item.id">{{item.nome}}</option>
                </select>
								<div class="error-form text-danger" v-if="!$v.usuario.acesso_perfil_id.required">Campo obrigatório</div>
							</div>
						</div>
					</div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.usuario.usuario.$error }">
								<label for="usuario" class="required">Usuário</label>
								<input v-model="usuario.usuario" type="text" class="form-control" >
								<div class="error-form text-danger" v-if="!$v.usuario.usuario.required">Campo obrigatório</div>
							</div>
						</div>
            <div class="col-md-6 col-sm-12">
							<div class="form-group">
								<label for="senha">Senha</label>
								<div class="input-group">
                  <input v-model="usuario.senha" autocomplete="new-password" :type="passwordFieldType" class="form-control">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm" @mousedown="displayPassword">
                      <i class="fas fa-eye"></i>
                    </span>
                  </div>
                </div>
							</div>
						</div>
          </div>
					<hr>
					<div class="row">
						<div class="col-md-12 mt-5">
							<button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
							<button type="submit" class="btn btn-primary float-right">SALVAR</button>
						</div>
					</div>
				</div>
			</form>
		</div>
  </div>
</template>

<script>
import usuarioRepository from './../../../services/api/usuarioRepository'
import perfilAcessoRepository from './../../../services/api/perfilAcessoRepository'
import { required } from 'vuelidate/lib/validators'
export default {
	validations: {
    usuario: {
			nome: {
				required
      },
      acesso_perfil_id: {
        required
      },
      usuario: {
        required
      }
		}
  },
	data () {
		return {
			usuario: {
        nome: null,
        usuario: null,
        acesso_perfil_id: null,
        senha: null
      },
      passwordFieldType: 'password',
      perfis: []
		}
	},
	created () {
    this.fetch()
		if (this.$route.query.id) {
			this.buscaUsuario(this.$route.query.id)
		}
	},
	methods: {
    async fetch () {
      let responsePerfis = await perfilAcessoRepository.listarPerfisAcesso()
      if (responsePerfis.data['success']) {
        this.perfis = responsePerfis.data['data']
      }
    },
    displayPassword: function () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
		buscaUsuario (id) {
			usuarioRepository.buscaUsuarioPorId(id).then(response => {
				this.usuario = response.data['data']
				this.usuario.senha = ''
			})
		},
		goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'Usuario' })
    },
		salvar () {
			this.$v.$touch()
			if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha o formulário corretamente!',
				})
      } else {
				if (this.usuario.id) {
					usuarioRepository.atualizarUsuario(this.usuario.id, this.usuario).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch(error => {
						console.log(error)
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				} else {
					usuarioRepository.salvarUsuario(this.usuario).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Usuário cadastrado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						} else {
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o cadastro!'
							})
						}
					}).catch(error => {
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				}
			}
		}
	}
}
</script>

<style>

</style>